let observer;

// Build observer
let callback = (entries, observer) => {
  var delay = 0;

  entries.forEach((entry) => {
    const animation = entry.target.dataset.animation ?? "fadeUp";

    if (entry.isIntersecting) {
      setTimeout(function () {
        // Add animation classes
        entry.target.classList.add(...animation.split(" "), "animated");
      }, delay);

      // Delay multiple intersections at once
      delay += 200;
      observer.unobserve(entry.target);
    }
  });
};

// Show when 48px from bottom
let options = {
  root: null,
  rootMargin: "0px 48px",
  threshold: 0,
};

observer = new IntersectionObserver(callback, options);

// Look for all animate classes
const animateOnScroll = window.addEventListener(
  "load",
  (event) => {
    document.querySelectorAll(".animate").forEach(function (i) {
      if (i) {
        observer.observe(i);
      }
    });
  },
  false,
);

export { animateOnScroll };
