import * as nav from "./modules/navigation.js";
import animateOnScroll from "./modules/observer.js";
import initialize from "./modules/map.js";

// Stop transitions before page has loaded
window.addEventListener(
  "load",
  (event) => {
    document.body.classList.remove("preload");
  },
  false,
);

// Toggle bio
$(".open-bio").click(function () {
  const bio = $(this).parent().parent().find(".bio");

  if (bio) {
    $(".bio").addClass("hidden");
    bio.removeClass("hidden");
  }
});

$(document).on("click", ".close-bio", function () {
  $(this).parent().parent().addClass("hidden");
});
